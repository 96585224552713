import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/GlobalPages.css";
import { API_BASE_URL } from "../config";
import axios from "axios";
import { FiCopy } from "react-icons/fi"; 
import { toast } from "react-toastify";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const URL = `${API_BASE_URL}api/user/allUsers`;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(URL);
        setUsers(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch users");
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleCopy = (userAddress) => {
    navigator.clipboard.writeText(userAddress).then(
      () => {
        toast("User Address copied!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  if (loading) {
    return <p>Loading users...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="UserList">
      <h3 className="text-center">User List</h3>
      <div className="card-body">
        <div className="container-fluid">
          <table className="table table-bordered table-striped table-hover">
            <thead className="bg-warning text-dark">
              <tr>
                <th>Id</th>
                <th>User Address</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {users.length > 0 ? (
                users.map((user, index) => (
                  <tr key={user._id}>
                    <td>{index + 1}</td>
                    <td>
                      {user.userAddress}
                      <button
                        className="user_list_btn"
                        onClick={() => handleCopy(user.userAddress)}
                      >
                        <FiCopy /> 
                      </button>
                    </td>
                    <td>{user.amount}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No users found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserList;
