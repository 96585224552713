import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useState } from "react";
import Userlogin from "./pages/User_Login";
import UserList from "./pages/User_List";
import ManageUserAddress from "./pages/ManageUserAddress";
import Header from "./Component/Header/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <>
      <Router>
        <ToastContainer position="top-center"/>
          {isLoggedIn && <Header />}   
        <Routes>
          <Route path="/" element={<Userlogin onLogin={handleLogin} />} />
          {isLoggedIn ? (
            <>
              <Route path="/user-list" element={<UserList />} />
              <Route path="/manage-user-address" element={<ManageUserAddress />} />
            </>
          ) : (
            // Redirect to login page if not logged in
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </Router>
    </>
  );
}

export default App;
