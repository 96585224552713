import React from "react";
import { Link } from "react-router-dom";
import "./Header.css"

const Header = () => {
  return (
    <>
      <div className="header">
        <nav className="navbar">
          <Link to="/user-list">User List</Link>
          <Link to="/manage-user-address">Manage User Addresses</Link>
        </nav>
      </div>
    </>
  );
};

export default Header;
