import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { toast } from "react-toastify";
import "../pages/GlobalPages.css";

const ManageUserAddress = () => {
  const [userAddresses, setUserAddresses] = useState(Array(10).fill(""));
  const [modifiedAddresses, setModifiedAddresses] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserAddresses = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axios.get(`${API_BASE_URL}api/winUser/getAllWinUsers`);
        const addresses = response.data.data;
        const UserlistAddress = addresses[0].selectedUserList;
        console.log("Fetched user addresses:", UserlistAddress);

        const updatedAddresses = Array(10).fill(""); 
        UserlistAddress.forEach((addressObj, index) => {
          const addressValue = Object.values(addressObj)[0];
          if (index < updatedAddresses.length) {
            updatedAddresses[index] = addressValue;
          }
        });

        setUserAddresses(updatedAddresses);
      } catch (error) {
        console.error("Error fetching addresses:", error);
        setError("Failed to load addresses. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserAddresses();
  }, []);

  const handleChange = (index, value) => {
    const updatedAddresses = [...userAddresses];
    updatedAddresses[index] = value;
    setUserAddresses(updatedAddresses);

    // Track the modified addresses
    setModifiedAddresses((prevModified) => ({
      ...prevModified,
      [index]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const addressSet = new Set();
    const existingAddresses = new Set();
    
    // Only process modified addresses
    const formattedPayload = Object.entries(modifiedAddresses)
      .map(([index, address]) => {
        if (address.trim() !== "") {
          if (existingAddresses.has(address)) {
            setError(`Address "${address}" already exists. Please remove duplicates.`);
            setLoading(false);
            return null;
          }

          if (addressSet.has(address)) {
            setError(`Address "${address}" already exists in your input. Please remove duplicates.`);
            setLoading(false);
            return null;
          }

          addressSet.add(address);

          return { [parseInt(index) + 1]: address }; // Format payload
        }
        return null;
      })
      .filter(Boolean);

    if (formattedPayload.length === 0) {
      setError("Please enter at least one user address.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}api/winUser/addWinUser`, {
        selectedUserList: formattedPayload,
      });

      console.log("Response from API:", response, response.data);
      if (!response.data.success) {
        setError("Error saving addresses: " + response.data.message);
      } else {
        toast.success("Addresses saved successfully!");

        // Update only the modified addresses in userAddresses
        const updatedUserAddresses = [...userAddresses];
        Object.entries(modifiedAddresses).forEach(([index, address]) => {
          updatedUserAddresses[parseInt(index)] = address;
        });
        
        setUserAddresses(updatedUserAddresses);
        setModifiedAddresses({});
      }
    } catch (error) {
      console.error("Error saving addresses:", error);
      setError("Failed to save addresses. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user_address">
      <h2>Manage User Addresses</h2>
      {error && <p className="error" style={{color:"red"}}>{error}</p>}
      <form onSubmit={handleSubmit} className="user_address_form">
        {userAddresses.map((address, index) => (
          <div key={index} className="manage_user_address">
            <input
              className="manage_user_address_input"
              type="text"
              value={address}
              placeholder={`User ${index + 1}:`}
              onChange={(e) => handleChange(index, e.target.value)}
            />
          </div>
        ))}
        <button type="submit" disabled={loading}>
          {loading ? "Saving..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ManageUserAddress;
