import React from "react";
import { API_BASE_URL } from "../config.js";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "../pages/GlobalPages.css";
import waveimg from "../assets/images/wave.png";
import bgimg from "../assets/images/bg.png";
import avtarimg from "../assets/images/avatar.png";

const Userlogin = ({ onLogin }) => {
  const navigate = useNavigate();
  const URL = `${API_BASE_URL}api/auth/sign-in`;

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await axios.post(URL, {
        email: values.email,
        password: values.password,
      });
      console.log("=============> response", response.data.message);

      if (response.status === 200) {
        toast.success(response.data.message);
        onLogin();
        navigate("/user-list");
      } else {
        setErrors({ email: "Login failed. Please check your credentials." });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({ email: error.response.data.message || "Login failed" });
      } else {
        setErrors({ email: "An unexpected error occurred. Please try again." });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <img className="wave" src={waveimg} alt="wave" />
      <div className="container">
        <div className="img">
          <img src={bgimg} alt="background" />
        </div>
        <div className="login-content">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <img src={avtarimg} alt="avatar" />
                <h2 className="title">Welcome</h2>
                <div className="input-div one">
                  <div className="i">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="div">
                    <h5 className={values.email ? "hidden-label" : ""}>
                      Email
                    </h5>
                    <Field type="email" name="email" className="input" />
                  </div>
                </div>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
                <div className="input-div pass">
                  <div className="i">
                    <i className="fas fa-lock"></i>
                  </div>
                  <div className="div">
                    <h5 className={values.password ? "hidden-label" : ""}>
                      Password
                    </h5>
                    <Field type="password" name="password" className="input" />
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-message"
                />
                <a href="#">Forgot Password?</a>
                <button
                  type="submit"
                  className="btn"
                  style={{ color: "white", fontWeight: "bold" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Logging in..." : "Login"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Userlogin;
