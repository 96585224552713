import React, { createContext, useContext, useState, useEffect } from 'react';

const UserAddressContext = createContext();

export const UserAddressProvider = ({ children }) => {
  const [userAddresses, setUserAddresses] = useState(Array(10).fill(""));

  useEffect(() => {
    const savedAddresses = JSON.parse(localStorage.getItem("userAddresses"));
    if (savedAddresses) {
      setUserAddresses(savedAddresses);
    }
  }, []);

  useEffect(() => {
    // Save addresses to local storage whenever they change
    localStorage.setItem("userAddresses", JSON.stringify(userAddresses));
  }, [userAddresses]);

  const updateAddress = (index, value) => {
    const updatedAddresses = [...userAddresses];
    updatedAddresses[index] = value;
    setUserAddresses(updatedAddresses);
  };

  return (
    <UserAddressContext.Provider value={{ userAddresses, updateAddress }}>
      {children}
    </UserAddressContext.Provider>
  );
};

export const useUserAddresses = () => {
  return useContext(UserAddressContext);
};
